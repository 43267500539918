<template>
  <div>
    <a-form-model
      :model="formInline"
      @submit="handleSubmit"
      @submit.native.prevent
    >
      <a-form-model-item label="所属公司">
        <a-select
          v-model="formInline.ecBcId"
          class="search-expand-value"
          :disabled="type == 'edit' ? true : false"
        >
          <a-select-option
            v-for="opt in filters.GetCompanies"
            :key="opt.bc_id"
            :value="opt.bc_id"
            >{{ opt.bc_name }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="额外收费项目名称">
        <a-input v-model="formInline.ecName" placeholder=""> </a-input>
      </a-form-model-item>
      <a-form-model-item label="收费类型">
        <!-- <a-input v-model="formInline.ecType" placeholder=""> </a-input> -->
        <a-select v-model="formInline.ecType" class="search-expand-value">
          <a-select-option
            v-for="opt in filters.ecType"
            :key="opt.ecType"
            :value="opt.ecType"
            >{{ opt.ecName }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="费用">
        <a-input v-model="formInline.ecCost" placeholder=""> </a-input>
      </a-form-model-item>
      <a-form-model-item label="说明">
        <a-textarea
          v-model="formInline.ecDesc"
          placeholder=""
          :auto-size="{ minRows: 3 }"
        >
        </a-textarea>
      </a-form-model-item>

      <a-form-model-item>
        <a-button :style="{ marginRight: '8px' }" @click="onClose">
          取消
        </a-button>
        <a-button type="primary" html-type="submit"> 提交 </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { GetCompanies } from "@/api/device";
import { AddExtraCharges, UpdateExtraCharges } from "@/api/apiJF/template";
export default {
  name: "MaterialBrandEdit",
  components: {},
  props: {
    record: {
      type: Object,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      formInline: {},
      formInlineEdit: {
        // mbId: "", //类别id
        ecBcId: "", //分公司id
        ecName: "", //名称
        ecType: "",
        ecCost: "", //费用

        // mbCreateTime: "", //创建时间
        ecDesc: "", //说明
      },
      filters: { GetCompanies: [], ecType: [] },
    };
  },
  created() {
    console.log(this.type, this.record);

    // 获取分公司信息
    GetCompanies().then((res) => {
      // console.log("GetCompanies", res.data);
      this.filters.GetCompanies = res.data;
    });
    this.filters.ecType = [
      { ecType: 1, ecName: "总金额比例" },
      { ecType: 2, ecName: "定额" },
    ];
  },
  mounted() {
    if (this.type == "edit") {
      for (let key in this.formInlineEdit) {
        for (let el in this.record) {
          if (key == el) {
            this.formInlineEdit[key] = this.record[el];
          }
        }
      }
      this.formInline = this.formInlineEdit;
    }
  },
  watch: {},
  methods: {
    handleSubmit(e) {
      if (this.type == "add") {
        // console.log("提交");
        // let addVerify = Object.keys(this.formInline);
        // console.log("addVerify", addVerify);
        // const orderParam = this.$order(this.formInline, addVerify);
        // const params = {
        //   ...orderParam,
        //   verify: this.$verify(orderParam, addVerify),
        // };
        let params = this.formInline;
        AddExtraCharges(params).then((res) => {
          // console.log(res);
          if (res.code == "200") {
            this.$message.success("添加成功");
            this.onClose();
          } else {
            this.$message.error(res.msg);
          }
        });
      } else if (this.type == "edit") {
        this.formInline.ecId = this.record.ecId;
        // this.formInlineEdit.mbCreateTime = this.record.mbCreateTime;
        let params = this.formInline;
        UpdateExtraCharges(params).then((res) => {
          // console.log(res);
          if (res.code == "200") {
            this.$message.success("修改成功");
            this.onClose();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    onClose() {
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-row {
  display: flex;
  width: 90%;
}
/deep/ .ant-form-item-control-wrapper {
  width: 80%;
}
/deep/ .ant-form-item-label {
  width: 200px;
}
/deep/ .ant-form {
  text-align: center;
}
</style>